import React, { useEffect } from "react";
import {
  Typography,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Tabs,
  Tab,
  Card,
} from "@mui/material";
import { Home, Assessment, Settings, AccountCircle } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import Chart from "../../components/Chart/Chart";
import Development from "../../assets/development.png";
import axios from "axios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ padding: "16px", flexGrow: 1 }}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SimpleTabs = () => {
  const [value, setValue] = React.useState(0);
  const [overallScore, setOverallScore] = React.useState(0);
  const history = useHistory();
  const [token, userId] = [
    localStorage.getItem("accessToken"),
    localStorage.getItem("user_id"),
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNavigation = (event, newValue) => {
    setValue(newValue);

    switch (newValue) {
      case 0:
        history.push("/Landingpage");
        break;
      case 1:
        history.push("/TotalAssessment");
        break;
      case 2:
        history.push("/SettingsPage");
        break;
      case 3:
        history.push("/ProfileNew");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Fetch average scores
    axios
      .get(`${process.env.REACT_APP_API_URL}/get_score/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((scoreResponse) => {
        // console.log("data lemaparan", scoreResponse.data.overall_average);
        setOverallScore(scoreResponse.data.overall_average);
        //  setAverageScore(scoreResponse.data.overall_average);
        localStorage.setItem(
          "roundedAverageScore",
          scoreResponse.data.overall_average.toFixed(2)
        );
      })
      .catch((error) => {
        // console.error("Error fetching average scores:", error);
      });
  });

  return (
    <Box style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Box style={{ flexGrow: 1, paddingBottom: "64px" }}>
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          style={{ marginTop: "30px", fontWeight: "bold" }}
        >
          Personal Score Average
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          centered
        >
          <Tab label="Peduli Insani" {...a11yProps(0)} />
          <Tab label="Pancadaya" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Card
            sx={{
              width: "60%",
              // maxWidth: 410,
              // height: 250, // Adjust card width
              margin: "0px auto",
              boxShadow: "0 10px 10px rgba(0,0,0,0.1)",
              borderRadius: "16px",
              padding: "12px",
            }}
          >
            <Typography sx={{textAlign: "center", fontFamily: "Poppins", fontSize: "16px", fontWeight: "bold" }}>Overall Average Score</Typography>
            <Typography sx={{textAlign: "center", fontFamily: "Poppins", fontSize: "14px", fontWeight: "medium", marginTop: "10px" }}>{overallScore.toFixed(2)}</Typography>
          </Card>
          <Chart />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <img
            className="w-[300px] h-[180px] mx-auto mt-5 mb-3"
            src={Development}
            alt="Development"
          />
          <Typography align="center" fontWeight={"bold"}>
            Coming soon
          </Typography>
        </TabPanel>
      </Box>
      <Box className="w-full max-w-md fixed bottom-0 left-0 right-0 mx-auto bg-white">
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          sx={{ boxShadow: "0px -1px 5px rgba(0, 0, 0, 0.2)" }}
        >
          <BottomNavigationAction
            label="Home"
            icon={<Home />}
            onClick={() => history.push("/Landingpage")}
          />
          <BottomNavigationAction
            label="Assessment"
            icon={<Assessment />}
            onClick={() => history.push("/TotalAssessmentNew")}
          />
          <BottomNavigationAction
            label="Settings"
            icon={<Settings />}
            onClick={() => history.push("/settingsPage")}
          />
          <BottomNavigationAction
            label="Profile"
            icon={<AccountCircle />}
            onClick={() => history.push("/Profile2")}
          />
        </BottomNavigation>
      </Box>
    </Box>
  );
};

export default SimpleTabs;
